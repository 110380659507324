var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
  'use strict';
  //validacio formulari
  var form = $(this);
  form.validate({
    errorPlacement: function(error, element) {
      if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
        if (element.closest('div').find('input').length > 1) {
          error.insertAfter(element.closest('div').find('p.type-label'));
        } else {
          error.insertAfter(element.parent('label'));
        }
      } else {
        error.insertAfter(element);
      }
    }
  });
  

});

//datapicker

//$('.houdiniForm .hasDatepicker').fdatepicker({
//  language: idioma,
//  format: 'dd/mm/yyyy',
//  startDate: '01/01/1900',
//  leftArrow:'<<',
//  rightArrow:'>>'
//});

//chosen
//$('.houdiniForm .chzn-select').select2();
//$('.multiple-select-help').hide(); //amaga misatge d'ajuda dels selects multiples
//$('.chzn-select').select2();



