$(document).ready(function() {
    $('tr[data-href]').on("click", function() {
        document.location = $(this).data('href');
    });
    $('.container img').each(function() {
   		$(this).addClass('img-responsive');
  	});
  	$('.col-dreta img').each(function() {
  		$(this).addClass('pull-right');
  	});
  	$('.col-esq img').each(function() {
  		$(this).addClass('pull-left');
  	});

    $(".selectpicker").selectpicker();
    $(".bootstrap-select").click(function () {
        $(this).addClass("open");
    });

    if ($('p.expert-diseases').length) {
    	var diseasesContainer = $('p.expert-diseases');
    	var secondaryLevelsSelector = '[data-level="4"], [data-level="5"], [data-level="6"], [data-level="7"], [data-level="8"], [data-level="9"]';

    	if (diseasesContainer.find(secondaryLevelsSelector).length > 1) {
    		diseasesContainer.find(secondaryLevelsSelector).hide();
    		diseasesContainer.append ('<a href="#more" id="show-more-diseases">... + more</a>');
    		$(document).on('click', '#show-more-diseases', function(){
    			$('#show-more-diseases').remove();
    			diseasesContainer.find(secondaryLevelsSelector).fadeIn();
    		});
    	}
    }

    $('.subdropdown').on('mouseover', function(){
    	$('.subdropdown').removeClass('open');
    	$(this).addClass('open');
    });
    $('.nav.navbar-nav > li > a, .nav.navbar-nav > li > ul > li > a').on('mouseover', function(){
    	$('.subdropdown').removeClass('open');
    });
    $('.nav.navbar-nav.navbar-right > li > a').on('mouseover', function(){
    	$('.subdropdown').removeClass('open');
    });


    $('.form-guidelines select#subnetwork').on('change', function(){
    	var selectInput = $('.form-guidelines select#disease_group');
    	var selectedSubnetwork = $(this).val();
    	var url = $(this).data('ajax-change');
    	$.ajax({
    		url: url,
    		data: {'subnetwork' : selectedSubnetwork},
    		success: function(data){

    			var options = '';
    			for (var i = 0; i < data.length; i++) {
    				options += '<option value="' + data[i]['key'] + '">' + data[i]['value'] + '</option>';
    			}

    			selectInput.html(options);
    			selectInput.selectpicker('refresh');
    		}
    	});
    });

    $(window).on('load', function() {
      if (window.location.hash.indexOf('#_') === 0) {
          var elem = $('#' + window.location.hash.replace('#_', ''));
          if (elem) {
            $([document.documentElement, document.body]).animate({
              scrollTop: elem.offset().top - 150
            }, 1000);
          }
      }
    });
});
