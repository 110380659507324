$(document).ready(function () {
  var loadTabWithUrl = function (tabElement) {
    var destinationTab = $(tabElement).attr('href');
    var destinationUrl = $(tabElement).attr('data-load-url');

    if ($(destinationTab).html().trim() === '') {
      $.ajax({
        method: 'get',
        url: destinationUrl,
        success: function (result) {
          $(destinationTab).html(result);
        }
      });
    }
  }

  $('.nav-tabs a[data-load-url]').click(function(e) {
    loadTabWithUrl(this);
  });

  $('.tab-button a').click(function(e) {
    e.preventDefault();

    var href = $(this).attr('href');

    $('.nav-tabs a[href="' + href + '"]').click();
  });
});
